<template>
  <section class="invite-section">
    <div class="invite-container">
      <h1 class="invite-title">
        Stay active. <span>Stay productive.</span>
      </h1>
      <span class="invite-subtitle">
        Simple time tracking app
      </span>
      <div class="invite-form">
        <div class="invite-text">
          <img class="invite-img" src="@/static/welcome.png" alt="">
          <h2 class="mb-3">
            Welcome to Timeprism
          </h2>
          <div>
            Your email <b>example@gmail.com </b>already registered
          </div>
        </div>
        <primary-button
          class="mb-5"
          full-width
          @click="$router.push('/login')"
        >
          Sign In
        </primary-button>
      </div>
    </div>
    <div class="about-dev">
      Powered by <a href="https://redentu.com">Redentu</a>
    </div>
  </section>
</template>

<script>
import PrimaryButton from '@/components/ui/Buttons/PrimaryButton';
import store from '@/store';

export default {
  name: 'AlreadyRegister',
  components: { PrimaryButton },
  beforeRouteEnter: (to, from, next) => {
    if (store.state.auth.isAuthorized) {
      next({
        name: 'Activity',
        params: {
          workspace: store.state.publicStore.selectedWorkspace.slug,
        },
      });
    }

    next();
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .invite {
    &-section {
      background-image: url("../../static/invite-bg.png");
      background-position: center top;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 150px;
      padding-bottom: 25px;
    }

    &-text {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 35px;
    }

    &-form {
      padding: 50px 95px;
      background: $white;
      min-width: 558px;
      border-radius: $radius-4;
      margin-bottom: 65px;
    }

    &-img {
      margin-bottom: 42px;
    }

    &-title {
      font-size: 48px;
      color: $white;

      span {
        font-size: inherit;
        color: $grey;
      }
    }

    &-subtitle {
      display: block;
      color: $grey-light;
      text-align: center;
      margin-bottom: 43px;
    }

    &-container {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  .about-dev {
    a {
      text-decoration: none;
    }
  }
</style>
